import React from 'react';
import dynamic from 'next/dynamic';
import CustomHead from '../components/common/head/Head';


const HeroArea = dynamic(() => import('../components/pages/homepage/HeroArea'), {
    loading: () => {
        return ""
    }, ssr: false
});
const ServiceArea = dynamic(() => import('../components/pages/homepage/ServiceArea'), {
    loading: () => {
        return ""
    }, ssr: false
});
const AboutArea = dynamic(() => import('../components/pages/homepage/AboutArea'), {
    loading: () => {
        return ""
    }, ssr: false
});
const ProductArea = dynamic(() => import('../components/pages/homepage/ProductArea'), {
    loading: () => {
        return ""
    }, ssr: false
});
const TestimonialArea = dynamic(() => import('../components/pages/homepage/TestimonialArea'), {
    loading: () => {
        return ""
    }, ssr: false
});
const ProposalArea = dynamic(() => import('../components/pages/homepage/ProposalArea'), {
    loading: () => {
        return ""
    }, ssr: false
});
const BlogArea = dynamic(() => import('../components/pages/homepage/BlogArea'), {
    loading: () => {
        return ""
    }, ssr: false
});
const ContactMapArea = dynamic(() => import('../components/pages/homepage/ContactMapArea'), {
    loading: () => {
        return ""
    }, ssr: false
});
const ContactInfoArea = dynamic(() => import('../components/pages/homepage/ContactInfoArea'), {
    loading: () => {
        return ""
    }, ssr: false
});

const HomePage = () => {
    return (<>
        <CustomHead />

        <HeroArea />
        <ServiceArea />
        <AboutArea />
        <ProductArea />
        <TestimonialArea />
        <ProposalArea />
        <BlogArea />
        <ContactMapArea />
        <ContactInfoArea />
    </>)
}


export default HomePage;